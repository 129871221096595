import React, { useState } from "react";
import {
  Button,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEventSession } from "../../providers/eventSessions";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import toast from "react-hot-toast";
import { useUser } from "../../providers/users";

dayjs.extend(timezone);
dayjs.extend(utc);

const FormContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const FieldsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 0),
}));

interface ICreateLinkFormData {
  date: Date;
  timezone: string;
  fields: {
    name: boolean;
    email: boolean;
    phone: boolean;
  };
}

interface IGeneratedLink {
  link: string;
  expirationDate: string;
}

interface IExpirableLinksProps {
  sessionId: string;
  StyledListItem: any;
  LinkItem: React.ComponentType<{ title: string; link: string }>;
}

const ExpirableLinks: React.FC<IExpirableLinksProps> = ({
  sessionId,
  StyledListItem,
  LinkItem,
}) => {
  const [generatedLink, setGeneratedLink] = useState<IGeneratedLink | null>(
    null
  );
  const { apiLoading } = useUser();
  const [showForm, setShowForm] = useState(true);
  const { createEventSessionLink } = useEventSession();
  const timezoneList = Intl.supportedValuesOf("timeZone");

  const formSchema = yup.object().shape({
    date: yup.date().required("Date is required"),
    timezone: yup.string().required("Timezone is required"),
    fields: yup.object().shape({
      name: yup.boolean().required("Name is required"),
      email: yup.boolean().required("Email is required"),
      phone: yup.boolean().required("Phone is required"),
    }),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICreateLinkFormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      timezone: dayjs.tz.guess(),
      fields: {
        name: true, // Always true and immutable
        email: true, // Default true but can be changed
        phone: true, // Default true but can be changed
      },
    },
  });

  const selectedTimezone = watch("timezone");

  const onSubmit = async (data: ICreateLinkFormData) => {
    try {
      // Convert the date to the selected timezone, then get timestamp
      const dateInSelectedTimezone = dayjs(data.date)
        .tz(data.timezone, true) // true keeps the local time and just changes zone
        .valueOf();

      const response = await createEventSessionLink({
        date: dateInSelectedTimezone,
        sessionId: sessionId,
        fields: {
          name: data.fields.name,
          email: data.fields.email,
          phone: data.fields.phone,
        },
      });

      if (response.link) {
        // Format the expiration date in the selected timezone
        const expirationDate = dayjs(response.date).format("MMM D, YYYY HH:mm");

        setGeneratedLink({
          link: response.link,
          expirationDate: expirationDate,
        });
        setShowForm(false);
        toast.success("Link created successfully");
      }
    } catch (error) {
      toast.error("Error creating link");
      console.error("Error creating session link:", error);
    }
  };

  if (!showForm && generatedLink) {
    return (
      <List>
        <LinkItem
          title={`Expirable Link (Session date: ${generatedLink.expirationDate} - Local Time)`}
          link={generatedLink.link}
        />
      </List>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <MobileDateTimePicker
                  label="Session Date and Time"
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  timezone={selectedTimezone}
                  slotProps={{
                    textField: {
                      variant: "filled",
                      fullWidth: true,
                      error: !!errors.date,
                      helperText: errors.date?.message,
                      InputProps: {
                        disableUnderline: true,
                      },
                      sx: {
                        backgroundColor: "#FFF",
                        boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
                        "& fieldset": { border: "none" },
                      },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="timezone-label">Timezone</InputLabel>
                <Select
                  labelId="timezone-label"
                  id="timezone-select"
                  variant="filled"
                  error={!!errors.timezone}
                  {...field}
                  sx={{
                    boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px",
                    backgroundColor: "#FFF",
                    "& fieldset": { border: "none" },
                  }}
                >
                  {timezoneList.map((timezone: string) => (
                    <MenuItem value={timezone} key={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
                {errors.timezone && (
                  <span className="error-message">
                    {errors.timezone.message?.toString()}
                  </span>
                )}
              </FormControl>
            )}
          />

          <Typography variant="subtitle1">Required Fields</Typography>
          <FieldsContainer>
            <Controller
              name="fields.name"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} checked={true} disabled={true} />
                  }
                  label="Name"
                />
              )}
            />

            <Controller
              name="fields.email"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Email"
                />
              )}
            />

            <Controller
              name="fields.phone"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Phone"
                />
              )}
            />
          </FieldsContainer>

          <Button
            type="submit"
            disabled={apiLoading}
            variant="contained"
            sx={{ height: "48px" }}
          >
            Create Link
          </Button>
        </FormContainer>
      </form>
    </div>
  );
};

export default ExpirableLinks;
