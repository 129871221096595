import * as yup from "yup";
import { useUser } from "../../providers/users";
import Loading from "../Loading";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../providers/tenant";
const IpGeolocationUpdateForm = () => {
  const { apiLoading } = useUser();
  const { updateIntegrations, tenant } = useTenant();
  const { t } = useTranslation();

  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    await updateIntegrations({ ipgeolocation: data });
  };

  const formSchema = yup
    .object()
    .shape({
      name: yup.string().required(t("field_required")).max(128, t("field_max")),
      apiKey: yup
        .string()
        .required(t("field_required"))
        .max(128, t("field_max")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Ip Geolocation</h3>
        <TextField
          error={errors.name === undefined ? false : true}
          helperText={errors.name?.message?.toString()}
          id="standard-name"
          defaultValue={
            tenant.integrations.ipgeolocation &&
            tenant.integrations.ipgeolocation.name
          }
          label={t("name")}
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("name")}
        />
        <TextField
          id="standard-apiKey"
          error={errors.apiKey === undefined ? false : true}
          helperText={errors.apiKey?.message?.toString()}
          defaultValue={
            tenant.integrations.ipgeolocation &&
            tenant.integrations.ipgeolocation.apiKey
          }
          label="Api Key"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

            textAlign: "center",
            backgroundColor: "#FFF",
            "& fieldset": { border: "none" },
          }}
          {...register("apiKey")}
        />

        {apiLoading ? (
          <Loading />
        ) : (
          <div>
            <Button
              type="submit"
              sx={{ borderRadius: "25px" }}
              variant="contained"
            >
              save
            </Button>
          </div>
        )}
      </form>
    </Container>
  );
};

export default IpGeolocationUpdateForm;
