import * as yup from "yup";
import { Container } from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import { useEventSession } from "../../providers/eventSessions";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";
import { useUser } from "../../providers/users";
interface IProps {
  setModalUpdateLiveAction: React.Dispatch<React.SetStateAction<boolean>>;
  liveAction: any;
  eventSession: IEventSession;
}
const ModalUpdateLiveAction = ({
  setModalUpdateLiveAction,
  eventSession,
  liveAction,
}: IProps) => {
  const { apiLoading } = useUser();

  const { editEventSession } = useEventSession();
  const [action, setAction] = useState(liveAction.action);
  const handleChangeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction((event.target as HTMLInputElement).value);
  };

  const { t } = useTranslation();
  type FormValues = yup.InferType<typeof formSchema>;
  const onSubmit = async (data: FormValues) => {
    const actionTime =
      data.hoursStart * 3600 + data.minutesStart * 60 + data.secondsStart;
    delete data.hoursStart;
    delete data.minutesStart;
    delete data.secondsStart;
    const liveActionIndex = eventSession.liveActions!.findIndex(
      (e: any) => e.id === liveAction.id
    );
    eventSession.liveActions![liveActionIndex] = {
      action: data.action,
      actionTime: actionTime,
      id: liveAction.id,
    };
    await editEventSession(eventSession.id, {
      liveActions: eventSession.liveActions,
    });
    setModalUpdateLiveAction(false);
  };

  const formSchema = yup
    .object()
    .shape({
      hoursStart: yup
        .number()
        .max(10)
        .typeError(t("field_required"))
        .required(t("field_required")),
      minutesStart: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
      secondsStart: yup
        .number()
        .max(60)
        .required(t("field_required"))
        .typeError(t("field_required")),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <Container>
      <div className="modalContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modalHeader">
            <h3>Product offer</h3>
            <div className="modalButtonsHeader">
              <Button
                variant="outlined"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                onClick={() => setModalUpdateLiveAction(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={apiLoading}
                sx={{ mr: 1, borderRadius: "25px" }}
                type="submit"
              >
                SAVE
              </Button>
            </div>
          </div>

          <FormControl>
            <InputLabel id="timezone-label">Action</InputLabel>
            <Select
              labelId="timezone-label"
              id="demo-simple-select"
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                "& fieldset": { border: "none" },
                backgroundColor: "#FFF",
                width: 442,
                mr: 2,
              }}
              value={action}
              label="Action"
              {...register("action")}
              //@ts-ignore
              onChange={handleChangeAction}
            >
              <MenuItem value={"disableChat"} key={"disableChat"}>
                Disable chat
              </MenuItem>

              <MenuItem value={"enableChat"} key={"enableChat"}>
                Enable chat
              </MenuItem>

              <MenuItem
                value={"smartChatToAdminOnly"}
                key={"smartChatToAdminOnly"}
              >
                Change smart chat to only Admin
              </MenuItem>
              <MenuItem value={"smartChatToOpen"} key={"smartChatToOpen"}>
                Change smart chat to open
              </MenuItem>
              <MenuItem
                value={"smartChatToModerated"}
                key={"smartChatToModerated"}
              >
                Change smart chat to moderated
              </MenuItem>

              <MenuItem
                value={"chatIndicatorToTrue"}
                key={"chatIndicatorToTrue"}
              >
                Change chat indicator to true
              </MenuItem>
              <MenuItem
                value={"chatIndicatorToFalse"}
                key={"chatIndicatorToFalse"}
              >
                Change chat indicator to false
              </MenuItem>
            </Select>
          </FormControl>
          <h3>Start offer</h3>
          <div className="durationDiv">
            <TextField
              id="standard-hour"
              error={errors.hours === undefined ? false : true}
              helperText={errors.hours?.message?.toString()}
              label={t("hours")}
              variant="filled"
              type="number"
              defaultValue={Math.floor(liveAction.actionTime / 3600)}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",

                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("hoursStart")}
            />
            <TextField
              id="standard-minutes"
              error={errors.minutes === undefined ? false : true}
              helperText={errors.minutes?.message?.toString()}
              label={t("minutes")}
              type="number"
              variant="filled"
              defaultValue={Math.floor((liveAction.actionTime % 3600) / 60)}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("minutesStart")}
            />
            <TextField
              id="standard-seconds"
              error={errors.seconds === undefined ? false : true}
              helperText={errors.seconds?.message?.toString()}
              label={t("seconds")}
              type="number"
              variant="filled"
              defaultValue={liveAction.actionTime % 60}
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                boxShadow: "rgba(3,3,3,0.1) 2px 2px 4px ",
                ml: 1,
                textAlign: "center",
                backgroundColor: "#FFF",
                "& fieldset": { border: "none" },
              }}
              {...register("secondsStart")}
            />
          </div>
        </form>
      </div>
    </Container>
  );
};

export default ModalUpdateLiveAction;
